import { FormattedMessage, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"
import { PrimaryButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

type SingleChoiceQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string) => void
}

export function SingleChoiceQuestion(props: SingleChoiceQuestionProps) {
  const {
    screen: { text, image, options = [] },
    onClick,
  } = props
  const intl = useIntl()

  const twoColumns = options.length > 4

  return (
    <div className="flex flex-col justify-between h-full">
      <QuestionTitle id={text} />
      <div
        className={twMerge(
          "pb-4 height-730:max-h-[230px]",
          options.length >= 4 && "height-575:max-h-[190px]",
        )}
      >
        {image && (
          <img
            src={image}
            className="height-730:max-h-[100%] height-730:mx-auto height-730:h-full"
          />
        )}
      </div>
      <div
        className={twMerge(
          "flex items-center gap-y-1 pb-4 height-575:gap-y-0",
          twoColumns ? "flex-wrap justify-center" : "flex-col",
        )}
      >
        {options.map((label) => (
          <PrimaryButton
            className={twMerge(
              twoColumns &&
                "w-[calc(50%-10px)] odd:mr-2.5 even:ml-2.5 last:!mr-0",
            )}
            key={label}
            onClick={() =>
              onClick(
                props.screen.id,
                intl.formatMessage({ id: label, defaultMessage: label }),
              )
            }
          >
            <div
              className={twMerge(
                "height-575:text-xs",
                twoColumns &&
                  options.some(
                    (option) =>
                      intl.formatMessage({ id: option, defaultMessage: option })
                        .length > 8,
                  ) &&
                  "text-xs",
              )}
            >
              <FormattedMessage id={label} defaultMessage={label} />
            </div>
          </PrimaryButton>
        ))}
      </div>
    </div>
  )
}
