import { PrimaryButton } from "components/molecules"
import { Screen } from "config/types"
import { FormattedMessage } from "react-intl"

type StubProps = {
  screen: Screen
  onClick: () => void
}

export function Stub(props: StubProps) {
  const {
    screen: { text, image },
    onClick,
  } = props

  return (
    <div className="flex flex-col h-full">
      <div>
        {image && (
          <img
            src={image}
            className="w-full m-auto mt-8 mb-8 height-650:max-w-[80%]"
          />
        )}
      </div>
      <div className="grow flex items-center justify-center">
        <div className="text-2xl text-center max-w-[310px] mx-auto">
          <FormattedMessage id={text} />
        </div>
      </div>
      <div className="w-full text-center pt-3 mb-6">
        <PrimaryButton onClick={onClick}>
          <FormattedMessage id="continue" />
        </PrimaryButton>
      </div>
    </div>
  )
}
