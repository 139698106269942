import { Screen } from "config/types"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { ReactComponent as CheckImg } from "assets/images/check.svg"
import { twMerge } from "tailwind-merge"
import BezierEasing from "bezier-easing"

type AnalyzeScreenProps = {
  screen: Screen
  onClick: () => void
  isActive: boolean
}

type ProgressProps = {
  options: string[]
  onEnd: () => void
}

const easing = BezierEasing(0.8, 0.76, 0.72, 0.15)

function Progress(props: ProgressProps) {
  const { options } = props
  const [progressNumbers, setProgressNumbers] = useState<number[]>(
    options.map(() => 0),
  )

  useEffect(() => {
    const activeIdx = progressNumbers.findIndex((progress) => progress < 100)
    if (activeIdx < 0) {
      props.onEnd()
      return
    }
    const newValue = progressNumbers.map((progress, index) =>
      activeIdx === index ? progress + 1 : progress,
    )
    const timeout =
      (easing(1 - progressNumbers[activeIdx] / 100) * 100) ** 2 / 100

    window.setTimeout(() => setProgressNumbers(newValue), timeout)
  }, [progressNumbers])

  return (
    <div className="w-full mt-6">
      {options.map((option, index) => (
        <div key={index} className="mb-6">
          <div className="w-full flex justify-between items-center">
            <div className="font-sans-bold uppercase text-sm">
              <FormattedMessage id={option} />
            </div>
            <div className="text-lg flex items-center">
              {progressNumbers[index] === 100 && (
                <CheckImg className="mr-1.5 h-[14px] w-[14px]" />
              )}
              {progressNumbers[index]}%
            </div>
          </div>
          <div className="mt-1 mb-4 h-[8px] w-full rounded-lg bg-gradient-to-r from-violet to-pink relative">
            <div
              className={twMerge(
                "absolute h-full right-0 top-0 bg-gray-150 rounded-r-lg",
                progressNumbers[index] < 5 && "rounded-l-lg",
              )}
              style={{ width: `${100 - progressNumbers[index]}%` }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export function AnalyzeScreen(props: AnalyzeScreenProps) {
  const {
    screen: { text, options = [] },
    onClick,
    isActive,
  } = props
  return (
    <div className="">
      <div className="text-3xl text-center mt-4 uppercase tracking-wide">
        <FormattedMessage id={text} />
      </div>
      <div>{isActive && <Progress options={options} onEnd={onClick} />}</div>
    </div>
  )
}
