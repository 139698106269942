import { FormattedMessage } from "react-intl"
import { PrimaryButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

type SexualityQuestionProps = {
  screen: Screen
  onClick: (formCid: string, reply: string) => void
}

const SEXUALITIES = ["h", "b", "g", "l", "q"]

export function SexualityQuestion(props: SexualityQuestionProps) {
  const {
    screen: { text, image },
    onClick,
  } = props

  const gender = localStorage.getItem("gender")
  const options = SEXUALITIES.filter((value) =>
    gender === "m" ? value !== "l" : value !== "g",
  )

  const selectSexuality = (sexuality: string) => {
    localStorage.setItem("sexuality", sexuality)
    onClick("sexuality", sexuality)
  }

  return (
    <div className="flex flex-col justify-between items-center h-full">
      <QuestionTitle id={text} />
      <div className="pb-4 grow flex items-center height-730:max-h-[230px] height-575:!max-h-[180px]">
        {image && (
          <img
            src={image}
            className="height-730:max-h-[100%] height-730:h-full height-730:mx-auto"
          />
        )}
      </div>
      <div className="flex flex-col items-center justify-center gap-y-1 w-full grow height-650:gap-y-0">
        {options.map((sexuality) => (
          <PrimaryButton
            key={sexuality}
            onClick={() => selectSexuality(sexuality)}
          >
            <FormattedMessage id={`sexuality.${sexuality}`} />
          </PrimaryButton>
        ))}
      </div>
    </div>
  )
}
