import { FormattedMessage } from "react-intl"
import { PrimaryButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"

import maleImg from "assets/images/questions/v2-gender-m.webp"
import femaleImg from "assets/images/questions/v2-gender-f.webp"

type GenderQuestionProps = {
  screen: Screen
  onClick: (formCid: string, reply: string) => void
}

type Gender = "f" | "m"

const GENDERS: Gender[] = ["f", "m"]
const GENDER_IMAGES: Record<Gender, string> = {
  f: femaleImg,
  m: maleImg,
}

export function GenderQuestion(props: GenderQuestionProps) {
  const {
    screen: { text, image, version, title },
    onClick,
  } = props

  const selectGender = (gender: string) => {
    localStorage.setItem("gender", gender)
    onClick("gender", gender)
  }

  switch (version) {
    case "v2":
      return (
        <div className="flex flex-col justify-start items-center h-full px-4">
          <div className="font-serif text-4xl text-center mb-8 mt-3 height-575:text-2xl height-575:mb-5">
            <FormattedMessage id={title} />
          </div>
          <div className="text-3xl tracking-wide text-center height-575:text-2xl">
            <FormattedMessage id={text} />
          </div>
          <div className="grow flex items-center justify-center gap-x-2">
            {GENDERS.map((gender) => (
              <PrimaryButton
                key={gender}
                theme="violet"
                onClick={() => selectGender(gender)}
              >
                <div>
                  <img src={GENDER_IMAGES[gender]} className="mb-2" />
                  <FormattedMessage id={`v2.gender.${gender}`} />
                </div>
              </PrimaryButton>
            ))}
          </div>
        </div>
      )
    default:
      return (
        <div className="flex flex-col justify-between items-center h-full">
          <QuestionTitle id={text} />
          <div className="pb-4 grow flex items-center height-730:max-h-[260px]">
            {image && (
              <img
                src={image}
                className="height-730:max-h-[100%] height-730:h-full height-730:mx-auto"
              />
            )}
          </div>
          <div className="flex flex-col items-center justify-center gap-y-2 w-full pb-4">
            {GENDERS.map((gender) => (
              <PrimaryButton key={gender} onClick={() => selectGender(gender)}>
                <FormattedMessage
                  id={`gender.${gender}`}
                  values={{
                    b: (chunks) => <span>{chunks}</span>,
                  }}
                />
              </PrimaryButton>
            ))}
          </div>
        </div>
      )
  }
}
