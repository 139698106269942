import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import AriesPng from "assets/images/questions/aries.png"
import TaurusPng from "assets/images/questions/taurus.png"
import GeminiPng from "assets/images/questions/gemini.png"
import CancerPng from "assets/images/questions/cancer.png"
import LeoPng from "assets/images/questions/leo.png"
import VirgoPng from "assets/images/questions/virgo.png"
import LibraPng from "assets/images/questions/libra.png"
import ScorpioPng from "assets/images/questions/scorpio.png"
import SagittariusPng from "assets/images/questions/sagittarius.png"
import CapricornPng from "assets/images/questions/capricorn.png"
import AquariusPng from "assets/images/questions/aquarius.png"
import PiscesPng from "assets/images/questions/pisces.png"
import { ReactComponent as ButtonSvg } from "assets/images/small-button.svg"

export const SIGNS = [
  { key: "Aries", img: AriesPng },
  { key: "Taurus", img: TaurusPng, mirror: true },
  { key: "Gemini", img: GeminiPng },
  { key: "Cancer", img: CancerPng },
  { key: "Leo", img: LeoPng, mirror: true },
  { key: "Virgo", img: VirgoPng },
  { key: "Libra", img: LibraPng, mirror: true },
  { key: "Scorpio", img: ScorpioPng },
  { key: "Sagittarius", img: SagittariusPng },
  { key: "Capricorn", img: CapricornPng, mirror: true },
  { key: "Aquarius", img: AquariusPng },
  { key: "Pisces", img: PiscesPng },
]

type ZodiakSignsProps = {
  onClick: (reply?: string) => void
  className?: string
  buttonClassName?: string
}

export function ZodiakSigns(props: ZodiakSignsProps) {
  const resolvedClassName = twMerge("grow flex items-center", props.className)

  return (
    <div className={resolvedClassName}>
      <div className="flex flex-wrap justify-center gap-y-5 gap-x-1">
        {SIGNS.map(({ key, img, mirror }) => (
          <button
            key={key}
            onClick={() => props.onClick(key)}
            className="relative group"
          >
            <ButtonSvg
              className={twMerge(
                "text-violet transition-colors group-hover:text-white",
                mirror && "-scale-x-100",
                props.buttonClassName,
              )}
            />
            <div className="absolute left-0 bottom-1.5 flex flex-col w-full items-center">
              <img src={img} />
              <FormattedMessage id={`zodiak.${key}`} />
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}
