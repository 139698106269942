import { IntlProvider } from "react-intl"
import { Route, Routes, useParams } from "react-router-dom"
import { StartingScreen, Survey } from "components/organisms"

import { SURVEY_CONFIG_V1 } from "config/v1"
import enLocales from "locales/en.json"
import ruLocales from "locales/ru.json"
import frLocales from "locales/fr.json"
import deLocales from "locales/de.json"
import { SURVEY_CONFIG_V2 } from "config/v2"
import { SURVEY_CONFIG_CHAT1 } from "config/chat-1"
import { SURVEY_CONFIG_CHAT2 } from "config/chat-2"
import { SURVEY_CONFIG_CHAT3 } from "config/chat-3"
import { SURVEY_CONFIG_CHAT4 } from "config/chat-4"
import { StorePage } from "components/organisms/store-page"
import { PAYGATE_CONFIG } from "config/paygate"

const MESSAGES: Record<string, any> = {
  en: enLocales,
  ru: ruLocales,
  fr: frLocales,
  de: deLocales,
}

export default function App() {
  const { locale = "en", ...rest } = useParams()
  const survey = rest["*"]
  const messages =
    survey === "nv3" ? MESSAGES[locale] || MESSAGES.en : MESSAGES.en

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      <Routes>
        <Route
          path="nv1"
          element={
            <Survey
              surveyId="nv1"
              steps={SURVEY_CONFIG_V1}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="nv2"
          element={
            <Survey
              surveyId="nv2"
              steps={SURVEY_CONFIG_V2}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="av2"
          element={
            <Survey
              surveyId="av2"
              steps={SURVEY_CONFIG_V2}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="chat_v1"
          element={<Survey surveyId="chat_v1" steps={SURVEY_CONFIG_CHAT1} />}
        />
        <Route
          path="chat_v2"
          element={<Survey surveyId="chat_v2" steps={SURVEY_CONFIG_CHAT2} />}
        />
        <Route
          path="chat_v3"
          element={<Survey surveyId="chat_v3" steps={SURVEY_CONFIG_CHAT3} />}
        />
        <Route
          path="chat_v4"
          element={<Survey surveyId="chat_v4" steps={SURVEY_CONFIG_CHAT4} />}
        />
        <Route path="nv3" element={<StorePage surveyId="nv3" />} />
        <Route
          path="paygate"
          element={
            <Survey
              surveyId="paygate"
              steps={PAYGATE_CONFIG}
              StartScreen={StartingScreen}
            />
          }
        />
      </Routes>
    </IntlProvider>
  )
}
