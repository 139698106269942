import { FormattedMessage, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"
import { MultiSelect, PrimaryButton } from "components/molecules"
import { QuestionTitle } from "components/atoms"

import type { Screen } from "config/types"
import { useState } from "react"

type MultipleChoiceQuestionProps = {
  screen: Screen
  onClick: (formCid?: string, reply?: string[]) => void
}

export function MultipleChoiceQuestion(props: MultipleChoiceQuestionProps) {
  const {
    screen: { text, image, options = [] },
    onClick,
  } = props
  const [selected, setSelected] = useState<string[]>([])
  const intl = useIntl()

  const twoColumns = options.length > 4

  return (
    <div className="flex flex-col justify-between h-full">
      <QuestionTitle id={text} />
      <div className="pb-4 height-730:max-h-[230px]">
        {image && (
          <img
            src={image}
            className="height-730:max-h-[100%] height-730:mx-auto height-730:h-full"
          />
        )}
      </div>
      <div
        className={twMerge(
          "flex w-full items-center gap-y-4",
          twoColumns ? "flex-wrap justify-center" : "flex-col",
        )}
      >
        <MultiSelect
          options={options}
          selected={selected}
          setSelected={setSelected}
        />
        <div className="w-full text-center pb-4">
          <PrimaryButton
            onClick={() =>
              onClick(
                props.screen.id,
                selected.map((i) =>
                  intl.formatMessage({ id: i, defaultMessage: i }),
                ),
              )
            }
          >
            <FormattedMessage id="continue" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}
