import { twMerge } from "tailwind-merge"

import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react"

import { ReactComponent as BorderX } from "assets/images/border-x.svg"
import { ReactComponent as BorderY } from "assets/images/border-y.svg"

export type CheckboxProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  selected?: boolean
}

export function Checkbox(props: CheckboxProps) {
  const { className, children, selected, ...restProps } = props

  const borderColor = selected ? "text-black" : "text-gray-50"
  const buttonColor = selected ? "bg-black text-white" : "bg-gray-50 text-black"

  const borderXClasses = twMerge("absolute w-full h-1.5", borderColor)
  const borderYClasses = twMerge("absolute w-1.5 h-full", borderColor)

  return (
    <div className={twMerge("p-1.5", className)}>
      <button className={twMerge("relative", buttonColor)} {...restProps}>
        <BorderX className={twMerge(borderXClasses, "-top-[5px]")} />
        <BorderX
          className={twMerge(borderXClasses, "-bottom-[5px] -scale-100")}
        />
        <BorderY
          className={twMerge(borderYClasses, "-left-[5px] -scale-y-100")}
        />
        <BorderY
          className={twMerge(borderYClasses, "-right-[5px] -scale-x-100")}
        />
        <div className="pt-[5px] pb-1 px-2 font-sans text-base">{children}</div>
      </button>
    </div>
  )
}
