import { twMerge } from "tailwind-merge"
import { FormattedMessage } from "react-intl"
import { BackgroundTemplate } from "components/templates"
import { Logo } from "components/atoms"

import { ReactComponent as ButtonSvg } from "assets/images/start-button.svg"
import lollypopLipsPng from "assets/images/lollypop-lips.png"
import lollypopPng from "assets/images/lollypop.png"
import lollypopTonguePng from "assets/images/lollypop-2.png"
import eyePng from "assets/images/eye.png"

export function StartingScreen({ onClick }: { onClick: () => void }) {
  return (
    <BackgroundTemplate className="bg-purple flex justify-center items-center">
      <div
        className={twMerge(
          "bg-gold-blob bg-no-repeat bg-center bg-[length:auto_100%]",
          "flex flex-col justify-center items-center",
          "w-full h-[486px] md:w-[750px] md:h-[537px] lg:w-[680px] lg:h-[487px]",
        )}
      >
        <div className="relative flex flex-col w-full items-center pt-6">
          <img
            src={lollypopLipsPng}
            className="absolute w-56 -top-48 left-7 md:w-auto md:left-28 md:-top-56 lg:left-24"
          />
          <img
            src={eyePng}
            className="absolute -top-20 right-2 md:right-40 lg:right-32"
          />
          <img
            src={lollypopPng}
            className="absolute w-32 -bottom-20 -left-2 md:w-auto md:left-32 md:-bottom-32 lg:left-28"
          />
          <img
            src={lollypopTonguePng}
            className="absolute -bottom-44 right-4 md:right-40 md:-bottom-36 lg:right-24"
          />

          <div className="bg-white-blob bg-cover px-4 py-2 mb-4">
            <Logo />
          </div>
          <h1 className="text-4xl uppercase font-pure text-center max-w-sm mb-7 font-bold">
            <FormattedMessage id="starting_screen.title" />
          </h1>
          <button
            className="relative text-pink hover:text-white transition-colors"
            onClick={onClick}
          >
            <ButtonSvg />
            <div className="flex justify-center items-center h-14 w-full absolute top-0 text-black text-2xl uppercase tracking-widest font-sans-bold">
              <FormattedMessage id="starting_screen.button" />
            </div>
          </button>
        </div>
      </div>
    </BackgroundTemplate>
  )
}
